<template>
  <v-badge :value="selectedOptions.length > 0" overlap :content="selectedOptions.length">
    <v-select
      ref="vSelect"
      v-model="selectedOptions"
      class="select-options-filter"
      :label="label"
      outlined
      dense
      :items="options"
      :menu-props="{ maxHeight: '400px', offsetY: true }"
      append-icon="expand_more"
      multiple
      hide-details
      :item-text="itemText"
      :item-value="itemValue"
      :return-object="returnObject"
      @blur="change"
    >
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0 && selectedOptions.length === 1" class="selected-text no-wrap">{{ item.text ? item.text : item.name }}</span>
        <span v-if="index === 0 && selectedOptions.length > 1" class="selected-text">{{ selectedOptions.length }} selec.</span>
      </template>

      <template #append-item>
        <div class="d-flex justify-end mx-2 mt-2" style="border-top: 1px solid #8080804f;">
          <mf-button class="mt-2" label="Limpar" outlined color="error" text size="sm" @click="clear" />
          <mf-button class="mt-2" color="primary" label="Aplicar" size="sm" @click="onClick" />
        </div>
      </template>
    </v-select>
  </v-badge>
</template>

<script>
export default {
  name: 'SelectOptionsFilter',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      required: true
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedOptions: []
  }),
  watch: {
    value() {
      this.selectedOptions = this.value
    }
  },
  mounted() {
    this.selectedOptions = this.value
  },
  methods: {
    clear() {
      this.selectedOptions = []
      this.change()
      this.$refs.vSelect.blur()
    },
    onClick() {
      this.change()
      this.$refs.vSelect.blur()
    },
    change() {
      this.$emit('change', this.selectedOptions)
    }
  }
}
</script>

<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
}

.select-options-filter {
  border-radius: 8px;
  margin-top: -2px;
  min-width: 128px;
  max-width: 190px;
  color: #334a58;

  .selected-text {
    color: #334a58;
    font-size: 14px;
  }

  ::v-deep .v-label {
    color: #334a58;
    font-size: 14px;
  }

  ::v-deep fieldset {
    border-color: #334a58;
  }

  ::v-deep .v-icon {
    color: #334a58;
  }
}
</style>
